<template>
  <div id="page-user-view">
    <div class="vx-row flex items-center mb-4">
      <div class="vx-col w-full flex justify-end" v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'finance'">
        <vs-button
          @click="newPricingPackage()"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large"
        >
        </vs-button>
      </div>
    </div>
    <shipblu-table
     :sst="true"
      multiple
      v-model="selected"
      :max-items="maximumItems"
      pagination
      :data="pricingPackages"
      :tableLoader="tableLoader"
    >

      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('Google Sheet URL')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'admin'">{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].name }}
          </shipblu-td>


          <shipblu-td :data="data[indextr].google_sheet_url">
            <a :href="data[indextr].google_sheet_url" target="blank">{{$t('Open in a new tab')}}</a>
          </shipblu-td>

          <shipblu-td :data="data[indextr].status">
            {{ data[indextr].status }}
          </shipblu-td>

          <shipblu-td v-if="$store.state.AppActiveUser.userRole === 'admin'">
            <vs-dropdown
              vs-trigger-click
              class="dd-actions cursor-pointer"
              @click="selected = []"
            >
              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-more-horizontal"
                class="mr-2"
              >
              </vs-button>
              <vs-dropdown-menu style="width: 190px">
                <vs-dropdown-item
                  @click="deletePricingPackage(data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="TrashIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Delete')}}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-pricing-package :type="type" :pricingPackageModal="pricingPackageModal" @pricingPackageModal="pricingPackageModal = $event" :prcingPackage="prcingPackage"
    @addPricingPackage="addPricingPackage" @loadPricingPackages="loadPricingPackages"></add-pricing-package>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import i18nData from '../../i18n/i18nData.js'
import AddPricingPackage from './components/AddPricingPackage.vue'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      tableLoader: false,
      prcingPackage: {
        name: '',
        google_sheet_url: ''
      },
      type: 'Add Pricing Package',
      pricingPackages: [],
      deleteData: {},
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      pricingPackageModal: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-accounts-pricing-packages`,
        query: {
          tab: this.$route.query.tab,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadPricingPackages()
    }
  },
  methods: {
    loadPricingPackages () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/pricing-packages/?limit=${this.maximumItems}&offset=${this.offset}`, 'get', null, true,
        (response) => {
          this.pricingPackages = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false 
        }
      )
    },
    newPricingPackage () {
      this.pricingPackageModal = true
      this.prcingPackage = {
        name: '',
        google_sheet_url: ''
      }
    },
    addPricingPackage () {
      sendRequest(false, false, this, 'api/v1/pricing-packages/', 'post', this.prcingPackage, true,
        () => {
          this.pricingPackageModal = false
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Pricing Package'] + i18nData[this.$i18n.locale][' has been created successfully!'],
            position: 'top-center'
          })
          this.loadPricingPackages()
        }
      )
    },
    deletePricingPackage (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/pricing-packages/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Pricing Package'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadPricingPackages()
        }
      )
    }
  },
  components: {
    AddPricingPackage,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadPricingPackages()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>

<style lang="scss">
.con-pagination-table{
  display: none;
}
</style>