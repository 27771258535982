<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="pricingPackageModal"
    :title="$t(type)"
    :buttons-hidden="true" autocomplete="nofill">
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" name="name" :label-placeholder="$t('Name') + '*'" v-model="prcingPackage.name" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required|url:require_protocol'" name="google sheet url" :label-placeholder="$t('Google Sheet URL') + '*'" v-model="prcingPackage.google_sheet_url" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('google sheet url')">{{ errors.first('google sheet url') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="addPricingPackage" class="active-btn btn">{{ $t('Add') }}</button>
      </div>
  </shipblu-prompt>
</template>
<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'

export default {
  props: ['type', 'pricingPackageModal', 'prcingPackage'],
  data () {
    return {
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  methods: {
    closeModal () {
      this.$emit('pricingPackageModal', false)
      this.$emit('loadPricingPackages')
    },
    addPricingPackage () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('addPricingPackage')
        }
      })
    }
  }
}
</script>