<template>
  <div id="admin-pickup-points">
    <h2 class="mb-5">{{$t('Pricing Packages')}}</h2>
    <vs-tabs v-model="activeTab" class="tabs-shadow-none" id="profile-tabs">
      <vs-tab icon-pack="feather" icon="icon-truck" :label="!isSmallerScreen ? $t('Delivery Pricing Packages') : ''">
        <div class="md:ml-4 md:mt-0 mt-4 ml-0">
          <delivery-pricing-packages-tab />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-archive" :label="!isSmallerScreen ? $t('Fulfillment Packages') : ''">
        <div class="md:ml-4 md:mt-0 mt-4 ml-0">
          <fulfillment-packages-tab />
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import DeliveryPricingPackagesTab from './DeliveryPricingPackagesTab.vue'
import FulfillmentPackagesTab from './FulfillmentPackagesTab.vue'

export default {
  data () {
    return {
      activeTab: 0,
      activeTabNameDic: {'delivery-pricing-packages': 0, 'fulfillment-packages': 1},
      activeTabDic: {0: 'delivery-pricing-packages', 1: 'fulfillment-packages'}
    }
  },
  watch: {
    '$route.query.tab' () {
      this.$router.push({
        query: {
          tab: this.activeTabDic[this.activeTab],
          page: 1
        }
      }).catch(() => {})
    },
    'activeTab' () {
      this.$router.push({
        query: {
          tab: this.activeTabDic[this.activeTab],
          page: this.$route.query.page ? Number(this.$route.query.page) : 1
        }
      }).catch(() => {})
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 500
    }
  },
  components: {
    DeliveryPricingPackagesTab,
    FulfillmentPackagesTab
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.activeTabNameDic[this.$route.query.tab] : '0'
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
